import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next"
import BarChart from "pages/AllCharts/chartist/barchart"
import LineChart from "pages/AllCharts/echart1/linechart"
import Candlestick from "pages/AllCharts/echart/candlestickchart"

const ForeCast = props => {
  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Row>
            <Col xl={12}>
              <Card style={{ background: "#E3FDF2",borderRadius: "24px," }}>
                <CardBody>
                <h5 className="text-black">Hourly Forecast</h5>
                <LineChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card style={{ borderRadius: "24px," }}>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px" }}
                      >
                        <CardBody>
                          <h5 className="text-black">Monthly Rainfall</h5>
                          <BarChart />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px" }}
                      >
                        <CardBody>
                          <h5 className="text-black">Uv Index</h5>
                          <Candlestick />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForeCast.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ForeCast)
