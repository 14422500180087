import DataService from "../DataService"

const getWeatherData = async ({limit ,offset,date}) => {
  let url = `data/list/?limit=${limit}&offset=${offset}&date=${date}&device_id=DFWSA1011`
  const data = await DataService.get(url)
  return data.data
}

const exportWeatherDataCSV = async ({limit ,offset,startDate,endDate}) => {
  let url = `csv/?limit=${limit}&offset=${offset}&start_date=${startDate}&end_date=${endDate}&device_id=DFWSA1011`
  const data = await DataService.get(url)
  return data
}

const exportWeatherDataPdf = async ({limit ,offset,startDate,endDate}) => {
  let url = `pdf/?limit=${limit}&offset=${offset}&start_date=${startDate}&end_date=${endDate}&device_id=DFWSA1011`
  const data = await DataService.get(url)
  return data
}

const getCurrentWeatherData = async ({limit ,offset,date }) => {
  let url = `data/current/date/?limit=${limit}&offset=${offset}&date=${date}&device_id=DFWSA1011`
  const data = await DataService.get(url)
  return data.data
}

export { getWeatherData, getCurrentWeatherData, exportWeatherDataCSV, exportWeatherDataPdf };
