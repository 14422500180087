import React, { Component } from "react"
import { Bar } from "react-chartjs-2"

class BarChart extends Component {
  render() {
    const data = {
      labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      datasets: [
        {
          label: {
            display: false,
          },
          backgroundColor: "#27B111",
          borderColor: "#27B111",
          borderWidth: 1,
          hoverBackgroundColor: "#27B111",
          hoverBorderColor: "#27B111",
          data: [65, 59, 81, 45, 56, 80, 50, 20],
        },
      ],
    }

    const option = {
      tootlbar: {
        show: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex]
            var meta = dataset._meta[Object.keys(dataset._meta)[0]]
            var total = meta.total
            var currentValue = dataset.data[tooltipItem.index]
            var percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            )
            return currentValue + " (" + percentage + "%)"
          },
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index]
          },
        },
      },
    }

    return (
      <React.Fragment>
        <Bar width={600} height={245} data={data} options={option} />
      </React.Fragment>
    )
  }
}

export default BarChart
