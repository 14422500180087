import Axios from "axios";

export const http = Axios.create({
  baseURL: "https://dfwsa.deepflow.in/api/",
  headers: { Accept: "application/json" },

});

http.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("admin-token");
  // config.headers["authorization"] = `Bearer ${accessToken}`;
  return config;
});

http.interceptors.response.use(undefined, function (err) {
  var statusCode = err.status;
  if (statusCode === undefined) {
    var lineSplit = err.toString().split("\n")[0].split(" ");
    statusCode = lineSplit[lineSplit.length - 1];
  }
  if (statusCode == 401) {
    localStorage.clear();
    window.location.href = "/auth/sign-in";
  }
  if (statusCode == 404) {
    throw err?.response;
  }
  if (statusCode == 400) {
    throw err?.response;
  }
  if (statusCode == 405) {
    throw "Method is not allowed";
  }
  if (statusCode == 500) {
    throw "Server is Facing Technical Difficulties, Please Contact Administrator";
  }
});


http.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

