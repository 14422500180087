import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Label,
  Input,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
import moment from "moment"
// Custom Scrollbar
import SimpleBar from "simplebar-react"

// import images
import weatherImage from "../../assets/images/cloud.png"
import temperatureIcon from "../../assets/images/temperature.svg"
import humidityIcon from "../../assets/images/humidity.svg"
import windIcon from "../../assets/images/direction.svg"
import speedIcon from "../../assets/images/wind-speed.svg"
import rainfallIcon from "../../assets/images/umbrella.svg"
import lightIcon from "../../assets/images/eye.svg"
import locationIcon from "../../assets/images//location.svg"
import climateIcon from "../../assets/images/climateIcon.svg"
import windStatus from "../../assets/images/windStatus.svg"
import airQuality from "../../assets/images/airQuality.svg"
//i18n
import { withTranslation } from "react-i18next"
import useWeatherDataQuery from "store/WeatherData/useWeatherDataQuery"
import BarChart from "pages/AllCharts/chartjs/barchart"
import DountChart from "pages/AllCharts/echart/doughnutchart"

const Dashboard = props => {
  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }
  //current date time
  const [date] = useState(new Date())

  const [dashboardData, setDashboardData] = useState([
    {
      temperature: 0,
      humidity: 0,
      direction: 0,
      speed: 0,
      rainfall: 0,
      pressure: 0,
      time: new Date(),
      lux: 0,
      id: 0,
    },
  ])

  //get previous month date
  // Get the current date
  let thisMonth = moment()
  let firstDayOfThisMonth = moment(thisMonth).startOf("month")

  let currentDateYYYMMDD = moment().format("YYYY-MM-DD")
  const [filterDate, setFilterDate] = useState(currentDateYYYMMDD)

  const query = {
    limit: 10,
    offset: 0,
    date: currentDateYYYMMDD,
  }

  const getDirection = angle => {
    switch (angle) {
      case 0:
        return "North"
      case 1:
        return "North-North East"
      case 2:
        return "North East"
      case 3:
        return "East-North East"
      case 4:
        return "East"
      case 5:
        return "East-South East"
      case 6:
        return "South East"
      case 7:
        return "South-South East"
      case 8:
        return "South"
      case 9:
        return "South-South West"
      case 10:
        return "South West"
      case 11:
        return "West-South West"
      case 12:
        return "West"
      case 13:
        return "West-North West"
      case 14:
        return "North West"
      case 15:
        return "North-North West"
      case 16:
        return "North"
      default:
        return ""
    }
  }

  const {
    data: dashboardDatas,
    isSuccess,
    refetch: weatherDataRefetch,
  } = useWeatherDataQuery.weatherData(query)

  const query2 = {
    limit: 10,
    offset: 0,
    date: filterDate,
  }

  const { data: currentDashboardData, refetch: currentWeatherDataRefetch } =
    useWeatherDataQuery.currentWeatherData(query2)

  React.useEffect(() => {
    if (isSuccess) {
      setDashboardData(dashboardDatas?.results[0])
    }
  }, [isSuccess, dashboardDatas])

  useEffect(() => {
    const interval = setInterval(() => {
      currentWeatherDataRefetch()
      weatherDataRefetch()
    }, 3600000)
    return () => clearInterval(interval)
  }, [])

  document.title = "Dashboard | Tata Coffee AWS"

  const dateTime = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })
  const currentDate = date.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  //function for convert date time to 12 hours format
  const convert24To12 = time => {
    if (!time) return "--"
    const [sHours, minutes] = time.split(":")
    const hours = +sHours % 12 || 12
    const amPm = +sHours < 12 ? "AM" : "PM"
    return `${hours}:${minutes} ${amPm}`
  }

  const roundOff = num => {
    if (!num) return 0
    return num.toFixed(2)
  }

  const convertToKph = speed => {
    if (!speed && speed === 0) return 0
    return speed * 3.6
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <div className="page-title-box">
          <Row className="align-items-center justify-content-between">
            <Col md={9} sm={12}>
              <h6 className="page-title">Dashboard</h6>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">
                  Welcome to Tata Coffee AWS Dashboard
                </li>
              </ol>
            </Col>

            <Col md={2} sm={12}>
              <div className="d-flex justify-content-end flex-column">
                <div className="">
                  <h5>{currentDate}</h5>
                  <h6>{dateTime} </h6>
                </div>
                <div className="d-flex justify-content-end flex-column">
                  <FormGroup>
                    {/* <Label for="filterDate">Date</Label> */}
                    <Input
                      value={filterDate}
                      onChange={e => setFilterDate(e.target.value)}
                      type="date"
                      name="filterDate"
                      id="filterDate"
                    ></Input>
                  </FormGroup>
                </div>
              </div>
            </Col>
            </Row>
            </div>
          <Row>
            <Col xl={12}>
              <Card style={{ borderRadius: "24px," }}>
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px" }}
                      >
                        <CardBody>
                          <h5 className="text-black mb-3">Temperature</h5>
                          <h6 className="text-black">
                            High :{" "}
                            <span className="text-success">
                              {roundOff(
                                currentDashboardData?.data?.temperature?.high ||
                                  0
                              )}{" "}
                              (°C)
                            </span>{" "}
                          </h6>
                          <h6 className="text-black">
                            Low :{" "}
                            <span className="text-danger">
                              {roundOff(
                                currentDashboardData?.data?.temperature?.low ||
                                  0
                              )}{" "}
                              (°C)
                            </span>{" "}
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col md={3}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px" }}
                      >
                        <CardBody>
                          <h5 className="text-black mb-3">Humidity</h5>
                          <h6 className="text-black">
                            High :{" "}
                            <span className="text-success">
                              {roundOff(
                                currentDashboardData?.data?.humidity?.high || 0
                              )}{" "}
                              (%)
                            </span>{" "}
                          </h6>
                          <h6 className="text-black">
                            Low :{" "}
                            <span className="text-danger">
                              {roundOff(
                                currentDashboardData?.data?.humidity?.low || 0
                              )}{" "}
                              (%)
                            </span>{" "}
                          </h6>
                        </CardBody>
                      </Card>
                    </Col> */}
                    <Col md={3}>
                      <Card
                        style={{
                          background: "#E3FDF2",
                          borderRadius: "24px",
                          paddingBottom: "10px",
                        }}
                      >
                        <CardBody>
                          <h5 className="text-black mb-3">Wind Speed</h5>
                          <h6 className="text-black">
                            High :{" "}
                            <span className="text-success">
                              {roundOff(
                                convertToKph(
                                  currentDashboardData?.data?.speed?.high
                                ) || 0
                              )}{" "}
                              (kph)
                            </span>{" "}
                          </h6>
                          <h6 className="text-black">
                            Low :{" "}
                            <span className="text-danger">
                              {roundOff(
                                convertToKph(
                                  currentDashboardData?.data?.speed?.low
                                ) || 0
                              )}{" "}
                              (kph)
                            </span>{" "}
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card
                        style={{
                          background: "#E3FDF2",
                          borderRadius: "24px",
                          paddingBottom: "10px",
                        }}
                      >
                        <CardBody>
                          <h5 className="text-black mb-3">Rain Fall</h5>
                          <h6 className="text-black">
                            High :{" "}
                            <span className="text-success">
                              {roundOff(
                                currentDashboardData?.data?.rainfall?.high || 0
                              )}{" "}
                              (mm)
                            </span>{" "}
                          </h6>
                          <h6 className="text-black">
                            Low :{" "}
                            <span className="text-danger">
                              {roundOff(
                                currentDashboardData?.data?.rainfall?.low || 0
                              )}{" "}
                              (mm)
                            </span>{" "}
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card style={{ borderRadius: "24px," }}>
                <CardBody className="ps-0 pb-0">
                  <div
                    className="d-flex justify-content-start align-items-center gap-2 p-2 px-3 ms-3"
                    style={{
                      width: "130px",
                      backgroundColor: "#E3FDF2",
                      borderRadius: "24px",
                    }}
                  >
                    <img src={locationIcon} alt="location" className="" />
                    <h6 className="mt-1">Coorg</h6>
                  </div>
                  <div className="ms-4">
                    <h3 className="card-title mt-2">Weather</h3>
                    <p className="text-muted mb-4">Now</p>
                  </div>
                  <Row>
                    <Col lg={6} style={{ position: "relative" }}>
                      <div style={{ position: "absolute", bottom: 0, left: 0 }}>
                        <img src={weatherImage} alt="weather" className="" />
                      </div>
                      {/* <div
                        style={{ position: "absolute", top: "0", right: "20%" }}
                      >
                        <img src={climateIcon} alt="weather" className="" />
                      </div> */}
                    </Col>
                    <Col lg={6} className="mb-5">
                      <Row>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={temperatureIcon}
                              alt="temperature"
                            />
                            <h5 className="mt-2"> Temperature</h5>
                            <p className="text-muted mb-4">
                              {roundOff(dashboardData?.temperature || 0)} (°C)
                            </p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={humidityIcon}
                              alt="humidity"
                            />
                            <h5 className="mt-2"> Humidity</h5>
                            <p className="text-muted mb-4">
                              {roundOff(dashboardData?.humidity || 0)} (%)
                            </p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={windIcon}
                              alt="direction"
                            />
                            <h5 className="mt-2">Wind Direction</h5>
                            <p className="text-muted mb-4">
                              {getDirection(dashboardData?.direction || 0)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={speedIcon}
                              alt="speedIcon"
                            />
                            <h5 className="mt-2"> Wind Speed</h5>
                            <p className="text-muted mb-4">
                              {roundOff(
                                convertToKph(dashboardData?.speed) || 0
                              )}{" "}
                              (kph)
                            </p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={rainfallIcon}
                              alt="rainfall"
                            />
                            <h5 className="mt-2"> Rain Fall</h5>
                            <p className="text-muted mb-4">
                              {roundOff(dashboardData?.rainfall || 0)} (mm)
                            </p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="text-start">
                            <img
                              width={24}
                              height={24}
                              src={lightIcon}
                              alt="light"
                            />
                            <h5 className="mt-2">Light Intensity</h5>
                            <p className="text-muted mb-4">
                              {roundOff(dashboardData?.lux || 0)} (lux)
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col xl={12}>
              <Card style={{ borderRadius: "24px," }}>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px" }}
                      >
                        <CardBody>
                          <h5 className="text-black">Chances of Rain</h5>
                          <BarChart />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px" }}
                      >
                        <CardBody>
                          <h5 className="text-black">Uv Index</h5>
                          <DountChart />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px",paddingBottom:"10px" }}
                      >
                        <CardBody>
                          <h5 className="text-black">Wind Status</h5>
                          <img src={windStatus} alt="windStatus" className="mt-3" />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={2}>
                    <Card
                        style={{ background: "#E3FDF2", borderRadius: "24px",paddingBottom:"10px" }}
                      >
                        <CardBody>
                          <h5 className="text-black">Wind Status</h5>
                          <img src={airQuality} alt="windStatus" className="mt-3" />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
