import { http, } from "../interceptor"

const get = url => {
  return http.get(url)
}

const post = (url, data) => {
  return http.post(url, data)
}

const del = url => {
  return http.delete(url)
}
const put = (url, data) => {
  return http.put(url, data)
}

const patch = (url, data) => {
  return http.patch(url, data)
}

const DataService = {
  get,
  post,
  del,
  put,
  patch,
}

export default DataService
