import { useQuery } from "react-query"
import { getCurrentWeatherData, getWeatherData } from "./weatherDataUrls"

const weatherData = query => {
  return useQuery(["weatherData", query], () => getWeatherData(query), {
    refetchOnWindowFocus: false,
  })
}

const currentWeatherData = (query) => {
  return useQuery(["currentWeatherData", query], () => getCurrentWeatherData(query), {
    refetchOnWindowFocus: false,
  })
}

const useWeatherDataQuery = {
  weatherData,
  currentWeatherData
}

export default useWeatherDataQuery
