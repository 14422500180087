import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

class Line extends Component {
  getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
      },
      grid: {
        zlevel: 0,
        x: 50,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
      },
      xAxis: {
        type: "category",
        data: ["10:00" , "12:00" , "14:00" , "16:00" , "18:00" , "20:00" , "22:00"],
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      series: [
        {
          data: [10, 40, 45, 50, 55, 65, 100],
          type: "line",
        },
      ],
      color: ["#27B111"],
      textStyle: {
        color: ["#74788d"],
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "300px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
export default Line
